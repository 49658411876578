<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-pb-none q-gutter-md">
        <q-btn
          flat
          color="primary"
          label="Back"
          @click="$router.go(-1)"
        ></q-btn>
        <p class="row justify-between">
          <a>Nama</a>
          <a>: {{ $route.params.nama }}</a>
        </p>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable q-mt-md bg-grey-4"
        style="height:calc(100vh - 190px);"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr class="text-left">
            <th>No</th>
            <th>Tanggal</th>
            <th>Surat</th>
            <th>Ayat</th>
            <th>Surat</th>
            <th>Ayat</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="tanggal.length == 0">
            <td class="text-center" colspan="5">
              Belum ada Riwayat Setoran Hafalan
            </td>
          </tr>
          <tr v-for="(val, i) in tanggal" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.tanggal | moment("DD-MMM-YYYY") }}</td>
            <td>{{ val.tampil[0] }}</td>
            <td>{{ val.tampil[1] }}</td>
            <td>{{ val.tampil[2] }}</td>
            <td>{{ val.tampil[3] }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      tanggal: [],
      searchTerm: "",
    };
  },
  mounted() {
    this.getSetoran();
  },
  methods: {
    getSetoran() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .get("/penilaian/tahfidz/getsetoranall/" + this.$route.params.id)
        .then((resp) => {
          resp.data.forEach((element) => {
            let temp = [];
            temp = element.setoran.split("%");
            element.tampil = temp;
          });
          this.tanggal = resp.data;
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
